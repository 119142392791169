import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"


export default () => (
  <StaticQuery
    query={graphql`
      query GroupHeaderQuery {
        allContentfulGroupPageHeader {
          edges {
            node {
              title1
              title2
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          totalCount
        }
      }
    `}
    render={data => (
      <div>
        <h1 className="title--inn">
          <span className="title--inn__alt dn">{ data.allContentfulGroupPageHeader.edges[0].node.title1 }</span>
          <span className="title--inn__text">{ data.allContentfulGroupPageHeader.edges[0].node.title2 }</span>
        </h1>
        <div className="hero__text" dangerouslySetInnerHTML={{ __html: data.allContentfulGroupPageHeader.edges[0].node.description.childMarkdownRemark.html }} />
      </div>
    )}
  />
)

