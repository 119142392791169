import { Link } from "gatsby"
import React from "react"
import Nav from "../common/nav"

const NavInn = ({ className }) => (
  <Nav className="nav--inn" />
)




export default NavInn



