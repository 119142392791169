import React from "react"
import { Link } from "gatsby"
// Importing Contentful Components
import GroupMember from "./groupMember"

import Layout from "../components/layout"
import NavInn from "../components/inner/navInn"
import SEO from "../components/seo"
import Hero from "../components/common/hero"
import Btn from "../components/common/button"

// Importing Contentful Components
import GroupHeader from "./groupHeader"


const GroupPage = () => (
  <Layout>
    <SEO title="Our Group" />
    <Hero className="hero--inn">
      <div className="cont--spaced">
        <div className="hero__head">
          <div className="hero__logo__cont">
            <a href="/" className="hero__logo">PrimeSet</a>
          </div>
          <NavInn className="" />
          {/* <Btn btnTitle="Explore" className="btn--prim" Link btnLink="/explore" /> */}
        </div>
        <div className="hero__content">
          <GroupHeader />
        </div>
      </div>
    </Hero>

    <section className="sect__cont sect--group sect--diagonal sect--diagonal--alone">
      <div className="sect__content">
        <div className="cont--spaced">
          <GroupMember />
        </div>
      </div>
    </section>
  </Layout>
)

export default GroupPage
